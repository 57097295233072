var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e7dfcea6d2bbb7ade8004866655db9d53d4ba887"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  CaptureConsole as CaptureConsoleIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  /** 1 for dev, 0.1 for production */
  tracesSampleRate: Number(process.env.NEXT_PUBLIC_TRACES_SAMPLE_RATE || '1'),
  integrations: [
    /** captures console logs */
    new CaptureConsoleIntegration({
      levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
    }),
    /** captures 500 http codes */
    new HttpClientIntegration({
      failedRequestStatusCodes: [500, 404],
    }),
  ],
});
