import { useEffect, useCallback, useState, useContext } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { deleteCookie } from 'cookies-next';
import { AppContext, eActionType } from '@contexts/appContext';
import { SocketContext } from '@contexts/SocketContext';
import LOG_HISTORIES from '@graphql/schema/mutations/logHistories.graphql';
import { isUserAuthenticated, authClientVar } from '@graphql/state';
import { OktaUserClaims } from '@types';

const useMyAccount = () => {
  const [userAccount, setUserAccount] = useState<OktaUserClaims>({} as OktaUserClaims);
  const { dispatch } = useContext(AppContext);
  const { disconnectSocket } = useContext(SocketContext);
  const isLoggedIn = useReactiveVar(isUserAuthenticated);
  const authClient = useReactiveVar(authClientVar);

  const [logHistories] = useMutation(LOG_HISTORIES);

  const setUserAccountData = useCallback(async () => {
    const {
      accessToken: { claims: { uid = '' } = {} } = {},
      idToken: {
        claims: {
          witsbyContractGroupId = '',
          avatar = '',
          email = '',
          exp = 0,
          givenName = '',
          familyName = '',
          sub = '',
          witsbyRole = [],
        } = {},
      } = {},
    } = (await authClient.tokenManager.getTokens()) as any;
    setUserAccount({
      uid,
      avatar,
      email,
      exp,
      givenName,
      familyName,
      sub,
      witsbyRole,
      witsbyContractGroupId,
    });
  }, [authClient?.tokenManager]);

  useEffect(() => {
    if (authClient && isLoggedIn) {
      setUserAccountData();
    }
  }, [authClient, isLoggedIn, setUserAccountData]);

  const signOut = async () => {
    logHistories({
      variables: {
        data: userAccount,
        logType: 'USER_ACTIVITY',
        actionType: 'LOGGED_OUT',
      },
    });
    disconnectSocket();
    // Clearing the cookie named 'witsby-role'
    await deleteCookie('witsby-role');
    await deleteCookie('magazineAccessed');
    await authClient.closeSession();
    await authClient.revokeAccessToken();
    localStorage.clear();
    dispatch({
      type: eActionType.RESET,
      data: null,
    });
    setUserAccount({} as OktaUserClaims);
    window.location.href = window.location.origin;
  };

  return {
    user: userAccount,
    signOut,
  };
};

export default useMyAccount;
