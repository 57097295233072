import Head from 'next/head';

interface IMetaTitle {
  title?: string;
  description?: string;
  showDefaultTags?: boolean;
}

const MetaTitle = ({
  title = 'ASCD',
  showDefaultTags = false,
  description = 'Witsby™ delivers on the true promise of K-12 professional learning and credentialing with the ultimate educator empowerment platform.',
}: IMetaTitle) => (
  <Head>
    {/* Title */}
    <title>{title} - Witsby</title>
    <meta name="title" content={`${title} - Witsby`} />
    <meta property="og:title" content={`${title} - Witsby`} key="title" />
    <meta name="twitter:title" content={`${title} - Witsby`} key="title" />
    
    {/* Description */}
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="twitter:description" content={description} />
    {showDefaultTags && (
      <>
        {/* Favicon */}
        <link rel="shortcut icon" href="https://www.ascd.org/favicon.ico" />

        {/* Author Meta */}
        <meta name="author" content="ASCD" />

        {/* FACEBOOK */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ASCD" />
        <meta property="og:url" content="www.ascd.org/witsby" />
        <meta
          property="og:image"
          content="https://library.ascd.org/m/b64f934a38a094f/webimage-Witsby_Logo_FullColor_TM.png?q=90"
        />

        {/* TWITTER */}
        <meta name="twitter:site" content="@ascd" />
        <meta name="twitter:url" content="www.ascd.org/witsby" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://library.ascd.org/m/b64f934a38a094f/webimage-Witsby_Logo_FullColor_TM.png?q=90"
        />

        {/* Keywords */}
        <meta name="keywords" content="ASCD, Witsby" />
      </>
    )}
  </Head>
);

export default MetaTitle;
